import * as React from 'react';


class Download extends React.Component<{}>
{
    public render()
    {
        return (
            <React.Fragment>
                <p>
                    Download data will be available soon.
                </p>
            </React.Fragment>
        );
    }
}

export default Download;
